
export var tableField = [
  { label: 'srm修改时间', code: 'srmLastUpdateTime', type: 'input', width: '50' },
  { label: '原日期', code: 'oldTime', type: 'input', width: '' },
  { label: '修改后日期', code: 'newTime', type: 'input', width: '' },
  { label: '修改人', code: 'updateUsername', type: 'input', width: '' },
  { label: '修改原因', code: 'updateDeliveryTimeReason', type: 'input', width: '' },
  {
    label: '是否14天',
    code: 'is14Day',
    type: 'function',
    handle: (index, data) => {
      return data.is14Day === '1' ? '是' : data.is14Day === '2' ? '否' : ''
    }
  }
]
